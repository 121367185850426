<template>
  <div>
    <el-drawer
        size="60%"
        :show-close="false"
        :before-close="onClose"
        append-to-body
        :visible.sync="drawerOpportunityDetail"
        :with-header="false">
      <!--  头部信息 start  -->
      <div v-if="!checkOpportunityShow">
        <el-alert
            :closable="false"
            title="此机会当前不在你的名下"
            type="error"
            center
            effect="dark">
        </el-alert>
      </div>

      <el-row type="flex" class="row-bg" style="margin: 0 20px;" justify="space-between">
        <el-col :span="14">
          <div style="display: flex;flex-direction: row;align-content: center">
            <span>机会号码：{{ dataMessage.telephone }}</span>
            <h4 style="display: inline-block;margin-left: 10px">学员信息
              <el-tag effect="dark" type="danger"> 回库时间:{{ dataMessage['backStoreTime'] }}</el-tag>
            </h4>

          </div>
        </el-col>
        <el-col :span="4" class="buttonSize-mini">
          <el-tooltip class="item" effect="dark" content="直呼拨打" placement="bottom"
                      v-if="callPanshi.qrcode === 'ON'">
            <el-button v-if="callPanshi.qrcode === 'ON'" type="success" size="mini" plain round
                       @click="calling($event,dataMessage.id)">直呼
            </el-button>
          </el-tooltip>
          <el-button hidden type="danger" size="mini" plain round @click="hangup($event)">挂断</el-button>
          <el-tooltip class="item" effect="dark" content="双呼拨打" placement="bottom"
                      v-if="callDouble.qrcode === 'ON'">
            <el-button v-if="callDouble.qrcode === 'ON'" type="success" size="mini" plain round
                       @click="callDoubleing($event,dataMessage.id)">双呼
            </el-button>
          </el-tooltip>

          <el-button type="warning" size="mini" plain round v-if="orderControl.switch === 'ON'"
                     @click="dialogOrderControlVisible = true">成单
          </el-button>
          <el-button type="warning" size="mini" plain round @click="dialogOrderVisible = true">下单</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="1">
          <el-steps :active="stepActive" align-center finish-status="success" space="12%"
                    v-if="detailInfo['communicationProgress']">
            <el-step v-for="(item,index) in communicationProgressTags" :title="item.tag"
                     @click.native="handleClickCommunicationProgress(index + 1,item.tag)"></el-step>
          </el-steps>
        </el-col>
      </el-row>
      <el-dialog title="下单" :visible.sync="dialogOrderVisible" width="70%" :append-to-body="true"
                 :modal-append-to-body="false" destroy-on-close>
        <PlaceOrder :id="opportunityId" :telephone="dataMessage.telephone" @close='close'></PlaceOrder>
      </el-dialog>
      <el-dialog title="成交订单" :visible.sync="dialogOrderControlVisible" width="500px" :append-to-body="true"
                 :modal-append-to-body="false" destroy-on-close>
        <el-form ref="OrderControlForm" :model="OrderControlForm" :rules="OrderControlFormRules"
                 class="transaction-order"
                 label-width="120px">
          <el-form-item label="商品名称:" prop="goodsName" required>
            <el-input size="small" v-model="OrderControlForm.goodsName"></el-input>
          </el-form-item>
          <el-form-item label="应收金额:" prop="amountReceivable" required>
            <el-input size="small" type="number" v-model="OrderControlForm.amountReceivable"></el-input>
          </el-form-item>
          <el-form-item label="缴费金额:" prop="paymentAmount" required>
            <el-input size="small" type="number" v-model="OrderControlForm.paymentAmount"></el-input>
          </el-form-item>
          <el-form-item label="缴费方式:" prop="paymentType" required>
            <el-select size="small" v-model="OrderControlForm.paymentType">
              <el-option label="微信" value="WEIXIN"></el-option>
              <el-option label="支付宝" value="ALIPAY"></el-option>
              <el-option label="后台开课" value="GIVING"></el-option>
              <el-option label="免费赠送" value="FREE"></el-option>
              <el-option label="平台购买" value="ACCOUNT"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款时间:" prop="paymentTime" required>
            <el-date-picker
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                v-model="OrderControlForm.paymentTime"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="支付状态:" hidden="hidden" prop="paymentStatus" required>
            <el-select size="small" v-model="OrderControlForm.paymentStatus">
              <el-option label="已支付" :value="3"></el-option>
              <el-option label="未支付" :value="1"></el-option>
              <el-option label="未付清" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单备注:" prop="placeOrderRemarks">
            <el-input size="small" v-model="OrderControlForm.placeOrderRemarks"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-divider></el-divider>
      <!--  头部信息 end  -->
      <!--  主体信息 start  -->
      <el-row type="flex" class="row-bg margin-bottom" justify="center">
        <el-col :span="11">
          <template v-if="detailInfo['studentInfo']">
            <el-form ref="formCont" :model="dataMessage" class="formCont" label-position="right">
              <template>
                <el-form-item v-for="studentItem in studentInfo" v-if="studentItem.status" :label="studentItem.label">
                  <el-col :span="12" v-if="studentItem.name === 'telephone'"
                          style="text-align-last: right;text-align: right;white-space:nowrap;">
                    <span>{{ dataMessage.telephone }}</span>&nbsp;
                    <el-tooltip class="item" effect="dark" content="点击复制手机号" placement="bottom"
                                v-if="dataMessage.telephone">
                      <i class="el-icon-document-copy" @click="copyTelephone"></i>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="2" v-else-if="studentItem.name === 'weiXin'"
                          style="text-align-last: right;text-align: right;white-space:nowrap;">
                    <span>* * * * * *</span>&nbsp;
                    <el-button type="text" @click="addWeChat">点击添加微信</el-button>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'opporType'">
                    <span v-if="dataMessage.type === 1">首咨</span>
                    <span v-if="dataMessage.type === 2">个人</span>
                    <span v-if="dataMessage.type === 3">常规</span>
                    <span v-if="dataMessage.type === 4">库存</span>
                    <span v-if="dataMessage.type === 5">唤醒</span>
                    <span v-else>--</span>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'studentName'">
                    <el-input v-model="dataMessage.studentName" placeholder="姓名"
                              @change="newChange('studentName')"></el-input>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'verifyAge'">
                    <el-input v-model="dataMessage.verifyAge" placeholder="年龄"
                              @change="newChange('verifyAge')"></el-input>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'verifyEducation'">
                    <el-select v-model="dataMessage.verifyEducation"
                               @change="newChange('verifyEducation')">
                      <el-option label="请选择" value=""></el-option>
                      <el-option v-for="(item,index) in education" :key="index" :label="item.name"
                                 :value="item.name"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'address'">
                    <el-select v-model="dataMessage.address" @change="newChange('address')">
                      <el-option label="请选择" value=""></el-option>
                      <el-option v-for="(item,index) in region" :key="index" :label="item.areaName"
                                 :value="item.areaName"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'subjectName'">
                    <el-select v-model="dataMessage.subjectName" @change="newChange('subjectName')">
                      <el-option label="请选择" value=""></el-option>
                      <el-option v-for="(item,index) in categoryList" :key="index"
                                 :label="item.subjectName"
                                 :value="item.subjectName"></el-option>
                    </el-select>
                  </el-col>

                  <el-col :span="12" v-else-if="studentItem.name === 'mediatorName'">
                    <span>{{ dataMessage.mediatorName ? dataMessage.mediatorName : '--' }}</span>
                  </el-col>
                  <el-col :span="12" v-else-if="studentItem.name === 'orderStatus'">
                  <span>{{
                      dataMessage.orderStatus == 0 ? '未成单' : (dataMessage.orderStatus == 2 ? '已下单' : dataMessage.orderStatus == 1 ? '已成单' : '--')
                    }}</span>
                  </el-col>
                  <el-col v-else-if="studentItem.name === 'opportunityTags'" :span="24">
                                    <span class="chanceList"
                                          v-if="dataMessage.intention">{{ dataMessage.intention }}</span>
                    <span class="chanceList" v-if="dataMessage.talkingSkills">{{ dataMessage.talkingSkills }}</span>
                  </el-col>
                  <el-col v-else :span="12" slot="label">
                    <div style="text-align-last: right;text-align: right;white-space:nowrap;">
                      {{ studentItem.label }}
                      <span class="font_Red">
                    {{
                          dataMessage[studentItem.name] ? dataMessage[studentItem.name] : '--'
                        }}</span>
                    </div>
                  </el-col>

                  <!--                <span v-if="studentItem.name === 'firstTime'"-->
                  <!--                      class="font_Red">{{ dataMessage.firstTime ? dataMessage.firstTime : '&#45;&#45;' }}</span>-->
                  <!--                <span v-if="studentItem.name === 'createTime'"-->
                  <!--                      class="font_Red">{{ dataMessage.createTime ? dataMessage.createTime : '&#45;&#45;' }}</span>-->
                  <!--                <span v-if="studentItem.name === 'lastTime'"-->
                  <!--                      class="font_Red">{{ dataMessage.lastTime ? dataMessage.lastTime : '&#45;&#45;' }}</span>-->
                  <!--                <span v-if="studentItem.name === 'collectionTime'"-->
                  <!--                      class="font_Red">{{ dataMessage.collectionTime ? dataMessage.collectionTime : '&#45;&#45;' }}</span>-->
                  <!--                <span v-if="studentItem.name === 'wakeTime'"-->
                  <!--                      class="font_Red">{{ dataMessage.wakeTime ? dataMessage.wakeTime : '&#45;&#45;' }}</span>-->

                </el-form-item>
              </template>
              <!--  开始额外参数功能  -->
              <template>
                <el-form-item style="width: 100%" v-for="info in infoList"
                              v-if="info.status && info.detail"
                              label-width="100px"
                              :label="info.label">
                  <div slot="label" style="text-align-last: right;text-align: right;white-space:nowrap; ">
                    {{ info.label }}
                    <el-tooltip :content="info.tips" placement="top" v-if="info.tips">
                      <i class="el-icon-info cursor"></i>
                    </el-tooltip>
                  </div>

                  <el-col v-if="info.type === 'input'">
                    <el-input v-model="information[info.name]"></el-input>
                  </el-col>
                  <el-col v-if="info.type === 'radio'">
                    <el-select v-model="information[info.name]" style="width: 100%">
                      <el-option label="请选择" value=""></el-option>
                      <el-option v-for="(item,index) in info.tson.dictList" :key="index"
                                 :label="item.value"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-col>
                  <el-col v-if="info.type === 'checkbox'">
                    <el-select v-model="information[info.name]" multiple style="width: 100%">
                      <el-option label="请选择" value=""></el-option>
                      <el-option v-for="(item,index) in info.tson.dictList" :key="index"
                                 :label="item.value"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </template>
              <!--  结束额外参数功能  -->
              <el-col :span="24"></el-col>
              <el-form-item>
                <el-button type="primary" class="button" @click="saveMessage">确定</el-button>
              </el-form-item>
            </el-form>
            <el-divider></el-divider>
          </template>


        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="11">
          <template v-if="detailInfo['remark']">
            <h4 style="margin-bottom: 8px">备注</h4>
            <el-row>
              <el-col :span="20">
              <span style="font-family: Helvetica Neue;font-size: 20px;color: #70707f"
                    v-text="dataMessage['remark']"></span>
                <el-input
                    type="textarea"
                    :rows="4"
                    resize="none"
                    placeholder="请输入内容"
                    v-model="newForm.remark"
                    @change="newChange('remark')">
                </el-input>
              </el-col>
              <el-col :span="4" style="position: absolute; bottom: 0; right: 0;">
                <el-button type="primary" class="button" @click="saveMessage">确定</el-button>
              </el-col>
            </el-row>
          </template>
          <History v-if="detailInfo['history']" ref="history" :id="opportunityId"></History>
          <template v-if="detailInfo['payInfo']">
            <h4>支付信息</h4>
            <el-row class="bj">
              <el-col :span="24" v-for="(item,index) in payInformation" :key="index">
                <el-row>
                  <el-col :span="24">
                    创建时间：{{ item.createTime }}
                  </el-col>
                  <el-col :span="24">
                    商品名: {{ item.goodsName }}
                  </el-col>
                  <el-col :span="24">
                    项目: {{ item.subjectName }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="10">
                    原价：{{ item.originalPrice }}
                  </el-col>
                  <el-col :span="12">
                    实际价格：{{ item.actualPrice }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    支付状态：{{
                      item.paymentStatus == 0 ? '未支付' : item.paymentStatus == 1 ? '支付成功' : '支付失败'
                    }}
                  </el-col>
                  <el-col :span="24">
                    支付时间：{{ item.paymentTime }}
                  </el-col>
                </el-row>
                <el-divider class="divider" v-if="payInformation.length > 1"></el-divider>
              </el-col>
            </el-row>
          </template>
        </el-col>
      </el-row>
      <!--  主体信息 end  -->
      <el-dialog title="机会回库" :visible.sync="backSync" append-to-body>
        <el-row>
          <el-col :span="12">机会ID:{{ this.dataMessage.id }}</el-col>
          <el-col :span="12">手机号码:{{ this.dataMessage.telephone }}</el-col>
        </el-row>
        <el-row style="margin: 20px 0">
          <b style="color: #dd6161;font-size: 20px">请选择回库原因:</b>
        </el-row>
        <el-row>
          <el-radio-group v-model="backIntention">
            <el-radio-button v-for="data in intentionDatas" :label="data.label" border></el-radio-button>
          </el-radio-group>
        </el-row>
        <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="backSync = false">关 闭</el-button>
        <el-button type="success" @click="batchGiveUp">确 定</el-button>
      </span>
      </el-dialog>
      <AddWechat ref="addWeChatDialog" :data-message="dataMessage"></AddWechat>
    </el-drawer>
  </div>
</template>

<script>
import History from '@/components/details/History'
import PlaceOrder from '@/components/details/PlaceOrder'
import * as searchApi from "@/api/system/searchDeploy";
import * as api from "@/utils/api"
import {getUserId, getUserName} from "@/utils/auth";
import {checkOpportunity} from "@/api/system/message"
//字典
import * as dictApi from "@/api/system/dictionary";
import * as backApi from '@/api/crm/backDepot'
import * as receiveApi from "@/api/system/receive";
import AddWechat from "@/views/consultant/components/AddWechat.vue";
import {copy} from "@/utils/utils";

export default {
    name: "OpportunityDetail",
    components: {
        AddWechat,
        History,
        PlaceOrder
    },
    props: {
        id: Number,
        drawerOpportunityDetail: {
            type: Boolean,
            require: true,
            default: false
        }
    },
    computed: {
    },
    data() {
        return {
            checkOpportunityShow: true,
            tagResult: [],
            backTime: 0,
            opportunityId: this.id,
            dialogOrderVisible: false,
            dataMessage: {},
            frozenForm: {
                shortcut: [],
                time: "",
                remark: ""
            },
            load: true,
            tableData: [],
            intention: [{label: '高意向'}, {label: '意向'}, {label: '跟踪'}, {label: '无意向'}],
            speechcraft: [{
                label: '开场白'
            }, {
                label: '报考条件'
            }, {
                label: '证书介绍'
            }, {
                label: '考试介绍'
            }, {
                label: '兼职就业'
            }, {
                label: '介绍班级'
            }, {
                label: '介绍价格'
            }, {
                label: '截杀'
            }],
            education: [{
                name: '博士'
            }, {
                name: '硕士'
            }, {
                name: '研究生'
            }, {
                name: '本科'
            }, {
                name: '大专'
            }, {
                name: '高中'
            }, {
                name: '其他'
            }],
            mediator: [
                '上传', '添加', '推广', '手机APP'
            ],
            orderStatus: [
                {label: '未成单', value: 0},
                {label: '已下单', value: 2},
                {label: '已成单', value: 1},
            ],
            communicationProgressTags: [],
            newForm: {},
            dateDate: {},
            payInformation: [],
            region: [],//地区
            categoryList: [],//项目类别
            callPanshi: {},//磐石云外呼按钮
            callDouble: {},//双呼外呼按钮
            detailInfo: {}, //详情信息
            studentInfo: [], //学员信息
            tagList: [], //标签列表
            stepActive: 1,
            orderControl: {},
            dialogOrderControlVisible: false,
            OrderControlForm: {
                paymentStatus: 3
            },
            paymentTime: '',
            OrderControlFormRules: {
                goodsName: [
                    {required: true, message: '请输入商品名称', trigger: 'blur'},
                ],
                amountReceivable: [
                    {required: true, message: '请输入商品原价', trigger: 'blur'},
                ],
                paymentAmount: [
                    {required: true, message: '请输入实付金额', trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择支付方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择支付时间', trigger: 'blur'},
                ],
                paymentStatus: [
                    {required: true, message: '请选择支付方式', trigger: 'blur'},
                ]
            },
            infoList: [],
            information: {},
            information_news: {},
            dictionaryList: [],
            dictMap: {},
            learning: [],
            intentionDatas: [],
            backIntention: "",
            backData: [],
            backSync: false,
        }
    },
    watch: {
        information(news, old) {
            this.information_news = news
        }
    },
    methods: {
        checkOpportunitys() {
            checkOpportunity(this.id).then(res => {
                if (res.success) {
                    this.checkOpportunityShow = res.data
                }
            })
        },
        onClose() {
            this.$emit('closeTheDrawer')
        },
        copyTelephone() {
            api.getTelephone(this.dataMessage.id).then(resp => {
                if (resp.success) {
                    copy(resp.msg)
                    this.$message.success('已成功复制');
                }else{
                  this.$message.error(resp.msg)
                }
            })
        },
        addWeChat() {
            this.addWeChatMessage = Object.assign({}, this.dataMessage)
            this.$refs.addWeChatDialog.openDialog()
        },
        findIntention() {
            receiveApi.findOptionCode('intention').then(res => {
                this.intentionDatas = res.data
            })
        },
        openBackSync() {
            this.backSync = true
        },
        /**
         * 批量回库
         */
        batchGiveUp() {
            if (!this.backIntention) {
                this.$message.error("请选择回库原因!")
                return
            }
            let params = {"intention": this.backIntention}
            api.giveMessageBack([this.dataMessage.id], params).then(res => {
                if (res.success) {
                    this.$message.success("操作成功")
                    this.backSync = false
                    this.$emit("closeTheDrawer")
                    this.$emit("myOpportunity")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("批量回库失败");
            })
        },
        /**
         * 格式化回库时间
         * @param t
         */
        formatBackTime(t) {
            if (t) {
                let now = (new Date().getTime() / 1000).toFixed(0)
                let c = t - now
                let day = ((c / 86400) + '').split(".")[0]
                if (day > 0) {
                    return day + '天后回库'
                }
                let hour = (((c - (day * 86400)) / 3600) + '').split(".")[0]
                return hour + '小时后回库'
            }
            return '待定'
        },
        findDictionaryList() {
            dictApi.list().then((res) => {
                if (res) {
                    console.log(res.data)
                }
            })
        },
        changeDate() {//预约时间选中后触发事件
            // this.nextTick(() => {
            // if (window.document.getElementsByClassName('el-input--small').nextSibling) {
            window.document.getElementsByClassName('el-input--small')[1].childNodes[1].focus()
            // }
            // });
        },
        //关闭弹框的事件
        close() {
            console.log('点击了关闭按钮======')
            this.dialogOrderVisible = false;
            this.getPayInformation();
            this.$refs.history.history('0')
        },
        onSubmit() {
            this.OrderControlForm.possessId = this.dataMessage.possessId
            this.OrderControlForm.opporId = this.dataMessage.id
            this.OrderControlForm.opporType = this.dataMessage.type

            console.log(this.OrderControlForm)
            this.$refs['OrderControlForm'].validate((valid) => {
                if (valid) {
                    api.clinchOrder(this.OrderControlForm).then(res => {
                        if (res.success) {
                            this.$confirm('是否将机会回库?')
                                .then(_ => {
                                    if (this.dataMessage.id) {
                                        api.giveUp([this.dataMessage.id]).then(rs => {
                                            if (rs.success) {
                                                this.$message.success("操作成功")
                                                this.$emit("closeTheDrawer")
                                            }
                                            this.dialogOrderControlVisible = false
                                        })
                                    }
                                })
                                .catch(_ => {
                                    this.dialogOrderControlVisible = false
                                });
                        } else {
                            this.dialogOrderControlVisible = false
                        }
                    })
                }


            });
        },
        onCopy(e) { 　　 // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {　　 // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
        getBackTime(opportunityId) {
            backApi.getByOpporId(opportunityId).then(res => {
                if (res.success) {
                    this.backTime = res.data.executeTime
                }
            })
        },
        customerMessage() {//获取详情信息
            api.customerMessage(this.opportunityId).then(res => {
                let data = res.data
                if (data.extra) {
                    let tson = JSON.parse(data.extra)
                    this.information = tson
                    this.information_news = {}
                }
                this.dataMessage = data
                this.learning = data.learning?.split(",") || []
                if (data.communicationProgress) {
                    for (let index in this.communicationProgressTags) {
                        let obj = this.communicationProgressTags[index]
                        if (obj.tag === data.communicationProgress) {
                            this.stepActive = index * 1 + 1
                            break
                        }
                    }
                }
                //设置当前的stepActive
                this.appointmentList()
                this.getPayInformation()
            })
            // this.getBackTime(this.opportunityId);

        },
        saveMessage() {//更新信息
            if (Object.keys(this.information_news).length > 0) {
                this.newForm["extra"] = JSON.stringify(this.information_news)
            }
            if (Object.keys(this.newForm).length === 0) {
                return false;
            }
            if (this.dataMessage.id) {
                api.saveMessage(this.dataMessage.id, this.newForm).then(res => {
                    if (res.msg.indexOf('操作成功') !== -1) {
                        this.$message({
                            message: '更新成功！',
                            type: 'success'
                        })
                        this.newForm = {};
                        this.$refs.history.history('0')
                        console.log(this.newForm)
                        // this.getBackTime(this.dataMessage.id)
                    }
                })
            }
        },
        /**
         * 获取配置
         * */
        Index() {
            api.index().then(res => {
                console.log(res, '获取配置')
                this.callPanshi = res.data.callPanshi;
                this.callDouble = res.data.callDouble;
                this.orderControl = res.data.orderControl;
            })
        },
        /**
         * 双呼拨打
         */
        callDoubleing(e, id) {
            api.doubleCall(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    e.target.innerText = '已呼叫'
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.warning("拨打失败");
            })
        },
        /**
         * 拨打
         */
        calling(e, id) {
            api.calling(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let next = target.nextSibling;
                    target.setAttribute("hidden", true)
                    next.removeAttribute("hidden")
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
            })
        },
        /**
         * 挂断
         */
        hangup(e) {
            api.hangUp().then(res => {
                if (res.success) {
                    //隐藏当前点击的挂断按钮，显示拨打按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let prev = target.previousSibling;
                    target.setAttribute("hidden", true)
                    prev.removeAttribute("hidden")
                }
            })
        },
        toGiveUp() {//回库
            if (this.dataMessage.id) {
                api.giveUp([this.dataMessage.id]).then(res => {
                    if (res.success) {
                        this.$message.success("操作成功")
                        this.$emit("closeTheDrawer")
                    }
                })
            }
        },
        /**
         * 冻结
         * @param e
         * @param id
         */
        frozen(id, freezeTime, params) {
            api.frozen(id, freezeTime, 2, params).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.handleFrozenFormClose()
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("冻结失败");
            })
        },
        learnChange() {
            this.newForm['learning'] = this.learning.join(",")
        },
        newChange(field) {
            if (field === 'remark') {
                this.newForm[field] = this.newForm[field]
            } else {
                this.newForm[field] = this.dataMessage[field]
            }
        },
        appointmentAdd() {//提交预约信息
            // this.$refs.history.getLocalTime(this.dateDate.appointmentTime)
            this.dateDate['opportunityId'] = this.dataMessage.id;
            api.appointmentAdd(this.dataMessage.id, this.dateDate).then(res => {
                if (res.code === 0) {
                    this.$message.success(res.msg);
                    this.appointmentList()
                    this.dateDate = {}
                }
            })
        },
        appointmentList() {//预约信息列表展示
            api.appointmentList(this.dataMessage.id).then(res => {
                this.tableData = res.data.reverse();
            })
        },
        getPayInformation() {//支付信息
            api.getPayInformation(this.dataMessage.id).then(res => {
                this.payInformation = res.data
            })
        },
        areasByType() {//省份
            api.areasByType(0).then(res => {
                this.region = res.data;
            })
        },
        subjectList() {//类别接口
            api.subjectList().then(res => {
                this.categoryList = res.data
            })
        },
        /**
         * popover显示时触发
         */
        handlePopoverShow() {
            this.frozenForm = {
                shortcut: [],
                time: "",
                remark: ""
            }
        },
        /**
         * 冻结表单提
         */
        handleFrozenFormSubmit(scope, opporId) {
            if (!this.frozenForm.time) {
                this.$message.warning("未选择冻结时间")
                return
            }
            if (!this.frozenForm.remark) {
                this.$message.warning("未填写冻结原因")
                return
            }
            if (this.frozenForm.remark.length > 15) {
                this.$message.warning("冻结原因字数不可超过15个字")
                return
            }

            this.frozenForm.opportunityId = this.opportunityId
            this.frozenForm.userId = getUserId()
            this.frozenForm.applicant = getUserName()
            this.frozen(this.opportunityId, this.frozenForm.time, this.frozenForm)
        },
        handleFrozenFormClose(scope) {
            this.$refs["frozen-popover"].doClose()
        },
        /**
         *
         */
        handleCheckbox() {
            let shortcut = this.frozenForm.shortcut
            if (shortcut) {
                this.frozenForm.remark = shortcut.toString()
            } else {
                this.frozenForm.remark = ""
            }
        },
        //配置开关
        configSwitch() {
            searchApi.list({classify: "COMMUNICATION_PROGRESS_TAG"}).then(res => {
                if (res.data) {
                    let tags = res.data[0]
                    let tempObj = JSON.parse(tags.data) ? JSON.parse(tags.data) : []
                    this.communicationProgressTags = tempObj
                }
            })

            searchApi.list({classify: "TAGS_LIST"}).then(res => {
                if (res.data) {
                    console.log(res.data)
                    for (let tag of res.data) {
                        tag['datas'] = JSON.parse(tag['data'])
                    }
                    this.tagList = res.data
                }
            })

            searchApi.list({classify: "DETAIL_INFO"}).then(res => {
                if (res.data) {
                    let tempObj = {}
                    for (let item of res.data) {
                        tempObj[item.name] = item.status
                    }
                    this.detailInfo = tempObj
                }
            })

            searchApi.list({classify: "STUDENT_INFO"}).then(res => {
                if (res.data) {
                    this.studentInfo = res.data
                }
            })

            searchApi.list({classify: "NEW_INFORMATION"}).then(res => {
                if (res.data) {
                    this.infoList = res.data
                    for (let i in res.data) {
                        let tson = JSON.parse(this.infoList[i].data)
                        this.infoList[i].tson = tson
                    }
                }
            })

        },
        /**
         * 点击沟通程度
         * @param index
         */
        handleClickCommunicationProgress(index, tag) {
            //后台保存操作
            api.setCommunicationProgress(this.opportunityId, tag, this.dataMessage.type).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.stepActive = index
                    this.$refs.history.reload()
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(error => {
                this.$message.error("设置沟通程度标签失败")
            })
        }

    },
    beforeMount() {
        this.configSwitch()
    },
    mounted() {
        this.areasByType()
        this.subjectList()
        this.Index()
        this.customerMessage()
        this.findIntention()
        this.checkOpportunitys()
    },
    activated() {
    },
}
</script>

<style scoped>
.intention {
    padding: 4px !important;
}

/deep/ .el-col-10 {
    line-height: 36px;
}

/deep/ .el-button.is-round {
    padding: 10px 23px;
}

/deep/ .el-divider--horizontal {
    margin: 10px 0;
}

h4 {
    margin: 0;
}

/deep/ .el-input__inner {
    height: 22px;
    line-height: 22px;
}

/deep/ .el-col-2 {
    text-align: center;
}

/deep/ .el-form-item {
    margin-bottom: 5px;
}

/deep/ .el-table td, /deep/ .el-table th {
    padding: 0;
}

/deep/ .el-table .cell {
}

.formCont {
    display: flex;
    flex-wrap: wrap;
}

.formCont /deep/ .el-form-item {
    width: 50%;
    display: table-cell;
}

.formCont /deep/ .el-input__inner {
    padding: 0 8px;
    height: 30px;
    line-height: 30px;
}

.whether {
    font-size: 8px;
    padding: 0 2px;
}

.font_Red {
    color: red;
    font-size: 8px;
}

.chanceList {
    margin-right: 5px;
    border: 1px solid #424287;
    padding: 4px;
    border-radius: 4px;
    font-size: 8px;
    color: #424287;
}

.button {
    padding: 4px 6px;
    float: right;
    cursor: pointer;
}

.bj {
    background: #E5E5E5;
    padding: 6px;
    border: 2px solid #C5C4C4;
    min-height: 100px !important;
    margin-top: 10px;
}

/deep/ .el-drawer__body {
    overflow: auto;
    /* overflow-x: auto; */
}

/deep/ .el-drawer /deep/ .rtl {
    overflow: scroll
}

/deep/ .el-radio-button {
    margin: 0 0 6px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/deep/ .el-radio-button__inner {
    border: none
}

/deep/ .el-radio-button--mini /deep/ .el-radio-button__inner {
    border: 1px solid #ccc;
    border-radius: 4px;
}

/deep/ .el-radio-button:last-child /deep/ .el-radio-button__inner, /deep/ .el-radio-button:first-child /deep/ .el-radio-button__inner {
    border-radius: 4px;
    border: 1px solid #ccc;
}

.history {
    height: 516px;
    border: 1px solid #8CC5FF;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
}

.margin-10 {
    margin: 0 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.bj /deep/ .el-divider--horizontal {
    background: #ccc !important;
}

.writing {
    font-size: 22px;
    cursor: pointer;
    padding: 0 4px;
    line-height: 36px;
    color: #666666;
}

.buttonSize-mini {
    vertical-align: middle;
    margin-top: 5px;
}

.buttonSize-mini /deep/ .el-button--mini, .el-button--mini.is-round {
    padding: 7px 15px;
}

/deep/ .el-button--success {
    color: #FFF;
    background-color: #2f8e00;
    border-color: #2f8e00;
}

/deep/ .el-step__title {
    font-size: 12px;
    line-height: 16px;
}

.transaction-order /deep/ .el-form-item {
    margin-bottom: 22px;
}

.transaction-order /deep/ .el-form-item .el-input__inner {
    height: 32px;
}

.cursor {
    cursor: pointer;
}
</style>
