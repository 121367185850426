<template>
  <div>
    <el-drawer
        size="60%"
        :show-close="false"
        :visible.sync="shopMessageObj.isShow"
        :with-header="false">

      <div style="padding: 0 20px;height: 100vh;overflow: auto;">

        <el-form :inline="true" ref="queryForm" :model="queryParams" class="demo-form-inline">
          <el-tooltip class="item" effect="dark" content="全部已读" placement="bottom">
            <el-button type="warning" icon="el-icon-view" size="small" circle @click="handleRead"></el-button>
          </el-tooltip>
          <!--          <el-form-item label="消息类型">-->
          <!--            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选-->
          <!--            </el-checkbox>-->
          <!--            <div style="margin: 15px 0;"></div>-->
          <!--            <el-checkbox-group v-model="messageTypes">-->
          <!--              <el-checkbox v-for="item in messageTypeArr" :label="item.value" :key="item.value">-->
          <!--                <el-badge :value="item.unRead" class="item">-->
          <!--                  {{ item.title }}-->
          <!--                </el-badge>-->
          <!--              </el-checkbox>-->
          <!--            </el-checkbox-group>-->
          <!--          </el-form-item>-->

                    <el-form-item label="消息状态" style="display: inline-flex;flex-direction: row;align-items: center;">
                      <el-radio-group size="mini" v-model="queryParams.isRead" @change="getList">
                        <el-radio-button :label="0">未读</el-radio-button>
                        <el-radio-button label="">全部</el-radio-button>
                      </el-radio-group>
                    </el-form-item>

          <!--          <el-form-item label="消息类型" style="display: inline-flex;flex-direction: row;align-items: center;">-->
          <!--            <el-select size="mini" v-model="queryParams.findType" placeholder="请选择消息类型">-->
          <!--              <el-option label="全部" :value="0"></el-option>-->
          <!--              <el-option label="我de邮箱" :value="1"></el-option>-->
          <!--              <el-option label="我de发送" :value="2"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->

          <!--          <el-form-item>-->
          <!--            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>-->

          <!--            <el-button style="display: inline-block; position: relative; margin-left: 300px"-->
          <!--                       v-permission="'sys:message:send'" @click="sendMessage">发布消息通知-->
          <!--            </el-button>-->
          <!--          </el-form-item>-->

          <el-form-item>
            <el-radio-group v-model="queryParams.status" size="mini" @input="getList">
              <el-radio-button :label="0">未完成</el-radio-button>
              <el-radio-button :label="2">全部</el-radio-button>
              <!--              <el-radio-button :label="1">已完成</el-radio-button>-->
            </el-radio-group>
          </el-form-item>
        </el-form>

        <!-- 主页列表   -->
        <!--        <span style="text-align: center">消息列表</span>-->
        <el-table
            :data="tableData"
            stripe
            border
            size="mini"
            style="width: 100%">
          <el-table-column
              align="center"
              prop="title"
              label="标题">
            <template v-slot="scope">
              <el-link :underline="false" @click="getMessage(scope.row)" v-if="scope.row.isRead === 1" type="success">
                {{ scope.row.title }}
              </el-link>
              <el-link :underline="false" @click="getMessage(scope.row)" v-else-if="scope.row.isRead === 0"
                       type="danger">{{ scope.row.title }}
              </el-link>
              <el-link :underline="false" @click="getMessage(scope.row)" v-else type="info">未知</el-link>
            </template>
          </el-table-column>
          <el-table-column
              prop="senderName"
              align="center"
              label="发送人"
              width="100">
          </el-table-column>
          <!--          <el-table-column-->
          <!--              prop="status"-->
          <!--              label="完成状态"-->
          <!--              width="120">-->
          <!--            <template v-slot="scope">-->
          <!--              <el-tag v-if="scope.row.status === 1" type="success">已完成</el-tag>-->
          <!--              <el-tag v-else-if="scope.row.status === 0" type="danger">未完成</el-tag>-->
          <!--              <el-tag v-else type="info">未知</el-tag>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="createTime"
              align="center"
              label="时间"
              width="200">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <el-button
                  size="mini"
                  @click="getMessage(scope.row)">查看详情
              </el-button>
              <!--              <el-popconfirm-->
              <!--                  confirm-button-text='好的'-->
              <!--                  cancel-button-text='不用了'-->
              <!--                  icon="el-icon-info"-->
              <!--                  icon-color="orange"-->
              <!--                  title="确认删除该消息吗？"-->
              <!--                  @confirm="handleDelete(scope.row.id)">-->
              <!--                <el-button-->
              <!--                    size="mini"-->
              <!--                    type="danger"-->
              <!--                    slot="reference">删除-->
              <!--                </el-button>-->
              <!--              </el-popconfirm>-->
              <el-button
                  v-if="scope.row.status === 1"
                  disabled
                  size="mini"
                  type="info"
                  slot="reference">已完成
              </el-button>
              <el-popconfirm
                  v-else-if="scope.row.status === 0"
                  confirm-button-text='确认'
                  cancel-button-text='点错了'
                  icon="el-icon-info"
                  icon-color="orange"
                  title="确认完成该消息吗？"
                  @confirm="finish(scope.row.id)">
                <el-button
                    size="mini"
                    type="success"
                    slot="reference">完成
                </el-button>
              </el-popconfirm>

            </template>
          </el-table-column>
        </el-table>
        <pagination
            background
            :total="queryParams.total"
            :page.sync="queryParams.current"
            :limit.sync="queryParams.size"
            @pagination="getList">
        </pagination>

        <!-- 消息详情   -->
        <el-drawer
            :title="messageItem.title"
            :append-to-body="true"
            :visible.sync="innerDrawer">

          <div style="padding: 0 20px">
            <div v-if="messageItem.contentText">
              <el-button v-if="messageItem.messageType === 8" type="success"
                         @click="downloadFile(messageItem.contentText)">点击下载文件
              </el-button>
              <el-input
                  v-else
                  type="textarea"
                  autosize
                  placeholder="请输入内容"
                  :value="messageItem.contentText">
              </el-input>
            </div>

            <div class="demo-image__preview" v-else-if="messageItem.contentImg">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="messageItem.contentImg"
                  :z-index="10000"
                  :preview-src-list="[messageItem.contentImg]">
              </el-image>
            </div>

            <div v-else-if="messageItem.contentTable"  style="overflow-y: scroll;height: 85vh;">
              <el-table
                  :data="messageItem.contentTable"
                  stripe
                  style="width: 100%">
                <el-table-column v-for="(item, index) in tableColumn"
                                 :key="index"
                                 :prop="item.name"
                                 :label="item.label">
                </el-table-column>
              </el-table>
            </div>

            <div style="margin-top: 20px">
              <!--              <el-button :disabled="messageItem.isRead === 1" @click="isRead(messageItem.id)">已读</el-button>-->
              <!--              <el-divider direction="vertical"></el-divider>-->
              <el-button
                  v-if="messageItem.status === 1"
                  disabled
                  size="mini"
                  type="info"
                  slot="reference">已完成
              </el-button>
              <el-popconfirm
                  v-else-if="messageItem.status === 0"
                  confirm-button-text='确认'
                  cancel-button-text='点错了'
                  icon="el-icon-info"
                  icon-color="orange"
                  title="确认完成该消息吗？"
                  @confirm="finish(messageItem.id)">
                <el-button
                    size="mini"
                    type="success"
                    slot="reference">完成
                </el-button>
              </el-popconfirm>
              <el-divider v-if="messageItem.opportunityId"
                          direction="vertical"></el-divider>
              <el-button v-if="messageItem.opportunityId"
                         @click="openOpportunityDetail(messageItem)">前往机会详情
              </el-button>
            </div>
          </div>

        </el-drawer>

      </div>

    </el-drawer>

    <!--机会详情组件-->
    <OpportunityDetail
        v-if="drawerOpportunityDetail"
        :id="messageItem.opportunityId"
        :drawerOpportunityDetail="drawerOpportunityDetail"
        @myOpportunity="myOpportunity"
        @closeTheDrawer="closeTheDrawer">
    </OpportunityDetail>

  </div>

</template>

<script>
import * as messageApi from "@/api/system/message";
import OpportunityDetail from "@/views/consultant/components/OpportunityDetail.vue";
import * as api from "@/utils/api";

export default {
    name: "Message",
    components: {OpportunityDetail},
    props: {
        shopMessageObj: {
            type: Object,
            require: true,
            default: null
        }
    },
    data() {
        return {
            drawerOpportunityDetail: false,
            queryParams: {
                page: 1,
                size: 10,
                current: 1,
                total: 0,
                isRead: 0,
                // findType: 1,
                status: 0
            },
            tableData: [],
            checkAll: false,
            isIndeterminate: true,
            messageTypeArr: [],
            // messageTypes: [],
            messageItem: {
                contentText: '',
                contentImg: '',
                contentTable: [],
            },
            innerDrawer: false,
            tableColumn: [],
            messageIdArr: [],
          unread:0,
        }
    },
    computed: {},
    mounted() {
        // this.getMessageTypeList();
        this.getList();
        // this.getUnReadCount();
    },
    methods: {
      handleRead(){
        messageApi.readAll().then(res=>{
          if(res.success){
            this.$message.success(res.msg)
            this.getList()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
        /**
         * 关闭右侧抽屉
         */
        closeTheDrawer: function () {
            this.drawerOpportunityDetail = false
        },
        /**
         * 查询机会
         *
         * @param params
         */
        myOpportunity(params) {
            api.myOpportunity(this.page.current, this.page.size, params).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.page.current = resEntity.current
                    this.page.size = resEntity.size
                    this.page.total = resEntity.total
                    this.tableData = resEntity.records
                    for (let i in this.tableData) {
                        if (this.tableData[i].extra) {
                            this.tableData[i].tson = JSON.parse(this.tableData[i].extra)
                        }
                    }
                    this.tabData = resEntity.records
                    if (this.sortRule.order !== null && this.sortRule.prop != null) {
                        this.handleSortChange(this.sortRule)
                    }
                    if (this.opporTypeRadios === '8') {
                        this.withinTwoDayBackNum = resEntity.total
                    } else if (this.opporTypeRadios === '9') {
                        this.withinTodayBackNum = resEntity.total
                    }
                } else {
                    this.$message.warning(res.msg);
                }
                this.loading = false
            }).catch(err => {
                this.loading = false
                this.$message.error("获取我的机会失败");
            })

        },
        /**
         * 打开机会详情
         * @param message
         */
        openOpportunityDetail(message) {
            // this.$set(this.shopMessageObj, 'isShow')
            this.drawerOpportunityDetail = true

            // const {opportunityId, telephone} = message
            // this.shopMessageObj.isShow = false
            // this.$router.push({
            //     path: '/Consultant'
            // })
            /*
            * 先打开页面, 再执行
            * */
            // this.$store.dispatch('message/setMessageParams', {
            //     type: 'gotoConsultant',
            //     opportunityId,
            //     telephone
            // })

        },
        /**
         * 下载文件单独处理
         * @param url
         */
        downloadFile(url) {
            window.open(url, "_blank")
        },
        // sendMessage() {
        //     console.log("发布消息通知");
        // },
        /**
         * 已读的方法
         * @param id
         */
        isRead(id) {
            messageApi.isRead(id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg)
                    this.getList();
                    // this.getMessageTypeList();
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        /**
         * 点击完成的方法
         * @param id
         */
        finish(id) {
            messageApi.finish(id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg);
                    this.getList();
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        /**
         * 删除的方法
         * @param id
         */
        // handleDelete(id) {
        //     this.messageIdArr = [];
        //     this.messageIdArr.push(id);
        //     messageApi.deleteMsg(this.messageIdArr.join(",")).then(resp => {
        //         if (resp.success) {
        //             this.$message.success(resp.msg);
        //             this.getList();
        //         } else {
        //             this.$message.error(resp.msg)
        //         }
        //     })
        // },
        /**
         * 获取单个message对象编辑
         * @param data
         */
        getMessage(data) {
            messageApi.getOne(data.id).then(resp => {
                if (resp.success) {
                    this.messageItem = resp.data
                    this.innerDrawer = true;
                    if (this.messageItem.contentTable) {
                        this.tableColumn = [];
                        let tableColumn = JSON.parse(this.messageItem.contentTable)
                        for (let item in tableColumn[0]) {
                            this.tableColumn.push({
                                name: item,
                                label: tableColumn[0][item]
                            })
                        }
                        this.messageItem.contentTable = tableColumn
                        this.messageItem.contentTable.shift()
                    }
                }else {
                    this.shopMessageObj.isShow = false
                    this.$message.error(resp.msg)
                }
            })
            if (data.isRead === 0) {
                setTimeout(() => {
                    this.isRead(data.id);
                }, 1000)
            }
        },
        /** 搜索按钮操作 */
        // handleQuery() {
        //     this.queryParams.page = 1;
        //     this.getList();
        // },
        /**
         * 全选的操作
         */
        // handleCheckAllChange(val) {
        //     let allType = this.messageTypeArr.map(e => e.value);
        //     this.messageTypes = val ? allType : [];
        //     this.isIndeterminate = false;
        // },
        /**
         * 获取页面数据
         *
         */
        getList() {
            // this.queryParams.messageTypeIds = this.messageTypes.join(",");
            messageApi.listPage(this.queryParams).then(resp => {
                if (resp.success) {
                    this.tableData = resp.data.data;
                    this.queryParams.total = resp.data.total;
                }
            })
        },
        /**
         * 获取消息类型以及未读数量
         */
        // getMessageTypeList() {
        //     messageApi.getMessageTypeList().then(resp => {
        //         if (resp.success) {
        //             this.messageTypeArr = resp.data
        //             if (this.messageTypeArr && this.messageTypeArr.length > 0) {
        //                 this.messageTypes = this.messageTypeArr.map(e => e.value);
        //             }
        //         }
        //     })
        // }
    }

}
</script>

<style scoped>
.demo-form-inline {
    line-height: 30px;
}

.demo-form-inline div {
    margin-bottom: 0;
}
</style>
